/**
 * Utilitná funkcia pre odstránenie diakritiky z textu (použitie funkcie removeAccents) a nahradenie ďalších znakov pre kompatibilitu s URL
 * @param {object} - String pre konverziu
 * @returns {string} - Upravený text
 * @example
 * ```javascript
 * import removeAccents from 'utilities/lib/removeAccents';
 *
 * const text = 'Utilitné funkcie Sportnet Online';
 * console.log(removeAccents(text)); // utilitne_funkcie_sportnet_online
 * ```
 */
export function removeAccents(text) {
    return text
        .toLowerCase()
        .replace(/[áàãâä]/gi, 'a')
        .replace(/[čç]/gi, 'c')
        .replace(/[ď]/gi, 'd')
        .replace(/[éèê]/gi, 'e')
        .replace(/[íìïî]/gi, 'i')
        .replace(/[ľĺ]/gi, 'l')
        .replace(/[ñň]/gi, 'n')
        .replace(/[óòöôõ]/gi, 'o')
        .replace(/[řŕ]/gi, 'r')
        .replace(/[š]/gi, 's')
        .replace(/[ť]/gi, 't')
        .replace(/[úùüûů]/gi, 'u')
        .replace(/[ý]/gi, 'y')
        .replace(/[ž]/gi, 'z')
        .replace(/[^a-zA-Z0-9]/g, ' ');
}
export default removeAccents;
