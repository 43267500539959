import React from 'react';
export default (size, color) => {
    const color1 = '#069';
    const color2 = '#fff';
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 45.71 46", width: size, height: size },
        React.createElement("g", { transform: "translate(-414.436 -395.745)" },
            React.createElement("g", { transform: "translate(414.436 395.745)" },
                React.createElement("path", { transform: "matrix(0.46,0,0,0.46,-0.145,0)", d: "M 7.5800781 -0.01171875 A 7.2565217 7.2565217 0 0 0 0.31445312 7.1621094 L 0.31445312 92.837891 A 7.2543478 7.2543478 0 0 0 7.6582031 100.01172 L 92.341797 100.01172 A 7.2543478 7.2543478 0 0 0 99.685547 92.837891 L 99.685547 7.1621094 A 7.2565217 7.2565217 0 0 0 92.341797 -0.01171875 L 7.6582031 -0.01171875 A 7.2565217 7.2565217 0 0 0 7.5800781 -0.01171875 z M 22.330078 16.755859 A 7.826087 7.826087 0 0 1 23.035156 16.785156 A 7.8 7.8 0 0 1 31.425781 24.587891 A 7.826087 7.826087 0 0 1 22.943359 32.396484 L 22.935547 32.386719 L 22.837891 32.386719 A 7.7695652 7.7695652 0 0 1 14.542969 24.587891 A 7.826087 7.826087 0 0 1 22.330078 16.755859 z M 66.615234 37.488281 A 14.95 14.95 0 0 1 67.265625 37.5 C 77.139538 37.5 84.542969 43.954654 84.542969 57.824219 L 84.542969 83.714844 L 69.542969 83.714844 L 69.542969 59.558594 C 69.542969 53.471637 67.370329 49.341797 61.935547 49.341797 A 8.2152174 8.2152174 0 0 0 54.234375 54.830078 A 10.286957 10.286957 0 0 0 53.742188 58.494141 L 53.742188 83.710938 L 38.742188 83.710938 L 38.738281 83.716797 C 38.738281 83.716797 38.936107 42.797503 38.738281 38.560547 L 53.738281 38.560547 L 53.738281 44.933594 A 14.95 14.95 0 0 1 66.615234 37.488281 z M 15.447266 38.554688 L 30.447266 38.554688 L 30.447266 83.716797 L 15.447266 83.716797 L 15.447266 38.554688 z ", fill: color1, style: { strokeWidth: 2.17391 } }),
                React.createElement("path", { style: { strokeWidth: 0.999999 }, d: "m 13.860849,38.509788 v -20.775 H 6.9608486 v 20.775 z m -3.452,-23.608 a 3.5999999,3.5999999 0 0 0 3.902,-3.591999 3.5879999,3.5879999 0 0 0 -3.86,-3.5890003 3.5999999,3.5999999 0 0 0 -3.9060004,3.5890003 3.5739999,3.5739999 0 0 0 3.8150004,3.588 h 0.045 z", fill: color2 }),
                React.createElement("path", { style: { strokeWidth: 0.999999 }, d: "m 17.675848,38.506789 h 6.9 v -11.6 a 4.7319998,4.7319998 0 0 1 0.227,-1.685 3.7789999,3.7789999 0 0 1 3.542,-2.525 c 2.5,0 3.5,1.9 3.5,4.7 v 11.112 h 6.9 v -11.91 c 0,-6.38 -3.406,-9.349 -7.948,-9.349 a 6.877,6.877 0 0 0 -6.268,3.5 h 0.046 v -3.012 h -6.9 c 0.091,1.949 0,20.771999 0,20.771999 z", fill: color2 })))));
};
